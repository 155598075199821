import Header from './components/Header';
import AuthorizedList from './components/AuthorizedList';
import WaitList from './components/WaitList';
import useWaitAuthorized from './hooks/useWaitAuthorized'


import './App.scss';

const App = () => {
	// Gets the constants from the useWaitAuthorized hook
	const { mv, setWaitList, waitList, setAuthorizedList, authorizedList } = useWaitAuthorized();

	return (
		<div className="App">
			<Header />
			<main className="main">
				<WaitList
					siteIDs={waitList}
					setSiteIDs={setWaitList}
					mv={mv} />
				<AuthorizedList
					siteIDs={authorizedList}
					setSiteIDs={setAuthorizedList}
					mv={mv} />
			</main>
		</div>
	);

};

export default App;

import { useState } from "react";
import "./SiteTable.scss";


const PluginTable = ({ pluginBySite }: { pluginBySite: Promise<Record<string, string[]>>; }) => {
    const [pluginData, setPluginData] = useState<Record<string, string[]> | null>(null);

    const p: Promise<Record<string, string[]>> = Promise.resolve(pluginBySite);

    p.then((value) => {
        setPluginData(value);
    });

    return (
        <article>
            {pluginData ? (
                Object.entries(pluginData).map(([plugin, sites]: [string, string[]]) => (
                    <details key={plugin}>
                        <summary>
                            <div className="site-list__infos">
                                <header><h3 className="site-list__title">{plugin}</h3></header>
                            </div>
                        </summary>
                        <div className="table__container display-none">
                            <table>
                                <tbody>
                                    {sites.map((site, index, array) => (
                                        <tr
                                            key={site}
                                            className={index === 0 ? "table__row--no-border" : ""}
                                        >
                                            {index === 0 && (
                                                <th scope="rowgroup" rowSpan={array.length}>
                                                    Sites
                                                </th>
                                            )}
                                            <td>{site}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </details>
                ))
            ) : (
                <p>Loading...</p>
            )}
        </article>
    );
}

export default PluginTable;

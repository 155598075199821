import './PriorityTags.scss';

import { SiteDataOK, PluginsList, Version } from "../types/SiteData";
import { diff as versionDiff } from 'semver';
import isDataOutdated from '../utils/isOutdated';
function formatVersion(version: string): string {
	// Si la version est au format x.y, ajoutez .0 pour la compléter
	if (/^[0-9]+\.[0-9]+$/.test(version)) {
		return version + '.0';
	}
	return version;
}

const Tag = ({ level, label }: { level: string; label: string; }) => {
	return (
		<div className={'priority-tag priority-tag--' + level}>
			{label} {level && level !== 'valid' && level !== 'outdated-data' && label !== 'Plugins' ? '(' + level + ')' : ''}
		</div>
	);
}

const PriorityTags = ({ siteData, versionsReference }: { siteData: SiteDataOK; versionsReference: Version }) => {

	function objectToArray(plugins: PluginsList[] | object): PluginsList[] {
		if (Array.isArray(plugins)) {
			return plugins;
		}
		return Object.values(plugins);
	}

	const areUpdatesAvailable = (plugins: PluginsList[]): boolean => {
		return plugins.reduce((acc, plugin) => acc || plugin.updateAvailable, false);
	}
	return (
		<div className='priority-tags__container'>
			<Tag label='PHP' level={versionDiff(siteData.phpVersion, versionsReference.php) ?? 'valid'} />
			<Tag label='WP' level={versionDiff(formatVersion(siteData.wpVersion), formatVersion(versionsReference.wp)) ?? 'valid'} />
			<Tag label='Plugins' level={areUpdatesAvailable(objectToArray(siteData.plugins)) ? 'major' : ''} />
			{
				isDataOutdated(siteData.date) ?
					<Tag label='Data older than 2 days' level='outdated-data' />
					:
					null
			}
		</div>
	);
}

export default PriorityTags;

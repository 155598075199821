import { useEffect, useState } from "react";

import Loader from "./Loader";
import SiteTable from './SiteTable';
import PluginTable from "./PluginTable";
import useSiteData from "../hooks/useSiteData";

import './AuthorizedList.scss';

import { SiteData, Version } from "../types/SiteData";


const AuthorizedList = ({ siteIDs, setSiteIDs, mv }: { siteIDs: string[], setSiteIDs: (siteIDs: string[]) => void, mv: (siteID: string) => void }) => {
	const [versionsReference, setVersionsReferences] = useState<Version | null>(null);
	const { pluginData } = useSiteData();
	const [isPluginOpened, setIsPluginOpened] = useState(false);
	const pluginBySite = pluginData(siteIDs);

	const openPlugin = () => {
		// When click on 'View plugin' btn toggle isPluginOpened 
		setIsPluginOpened(!isPluginOpened);
	}


	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_DOMAIN}/versions`)
			.then(response => response.json())
			.then((data: Version) => {
				setVersionsReferences(data);
			});
	}, []);

	useEffect(() => {
		fetch(`${process.env.REACT_APP_API_DOMAIN}/authorized`)
			.then(response => response.json())
			.then((data: string[]) => {
				setSiteIDs(data);
			});
	}, []);

	// Get all site data from siteIDs (chnage these constants to have an array of siteData)

	const updateSite = (siteIDs: string[], option: string = "",) => {
		let allSiteData: SiteData[] = []
		siteIDs.map((siteID) => {
			// the fetch force command request the update of data,
			// and thus a second fetch most be proceed
			const cmd = option === "force" ? "/?cmd=force" : "";
			fetch(`${process.env.REACT_APP_API_DOMAIN}/authorized/${siteID}${cmd}`, {
				method: "GET",
				signal: AbortSignal.timeout(5000)
			})
				.then((response) => {
					return response.json();
				})
				.then((data: SiteData) => {
					allSiteData.push(data);
				})
				.catch(error => {
					console.log(error);
				});
			return allSiteData;
		})
	};

	useEffect(() => {
		updateSite(siteIDs);
	}, [siteIDs]);


	return (
		<>
			<h2>Authorized list</h2>
			<div className='authorizedlist__container'>
				<button onClick={openPlugin}>Plugin view / Site view</button>
				{isPluginOpened ? (
					<div>
						<h3>Plugin</h3>
						<PluginTable pluginBySite={pluginBySite} />
					</div>
				) : <div className='authorizedlist__container'>
					<h3>Websites</h3>
					{
						versionsReference && siteIDs.length ?
							siteIDs.map(siteID => (
								<SiteTable key={siteID} versionsReference={versionsReference} siteID={siteID} mv={mv} />
							))
							: <Loader />
					}
				</div>
				}

			</div>

		</>
	);

};

export default AuthorizedList;